import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from "gatsby";
import slugify from "../utils/slugify";
import { ErrorBoundary } from "./error";

const Shop = (props) => {
  let { title, picture, urlPrefix = "project", price, marked } = props;

  let slug = `/${urlPrefix}/${slugify(title)}/`;

  if (!title) {
    return null;
  }
  return (
    <ErrorBoundary>
      <div className="col-sm-3 mt-5">
        <ShopImage slug={slug} picture={picture} title={title} marked={marked} />
        <TitleAndPrice title={title} price={price} slug={slug} />
      </div>
    </ErrorBoundary>
  );
};

export default Shop;

const TitleAndPrice = (props) => {
  let { title, price, slug } = props;

  return (
    <div>
      <hr className="p-0 m-0 mt-1 line-color" />
      <Link to={slug} className="link">
        <div className="text-dark medium">{title}</div>
      </Link>
      <hr className="p-0 m-0 line-color" />
      <div>
        <p className="text-right mb-0" style={{ color: "#B3B3B3", lineHeight: 1.9, fontSize: "80%" }}>
          {price}
        </p>
      </div>
      <hr className="p-0 m-0 line-color" />
    </div>
  );
};

const ShopImage = (props) => {
  let { slug, picture, title, marked } = props;

  return (
    <ErrorBoundary>
      <div style={{ position: "relative" }}>
        <Link to={slug}>
          <GatsbyImage image={picture} alt={title} className="circle-cursor" />
        </Link>
        <Mark marked={marked} />
      </div>
    </ErrorBoundary>
  );
};

const Mark = (props) => {
  let { marked } = props;

  if (!marked) {
    return null;
  }

  const data = useStaticQuery(graphql`
    query markQuery {
      allFile(filter: { name: { regex: "/mark_/" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 50)
            }
          }
        }
      }
    }
  `);

  let image = data.allFile.edges.map((image) => image.node).filter((image) => image.name === marked)[0];

  if (!image) {
    return null;
  }

  return (
    <div>
      <GatsbyImage
        image={image.childImageSharp.gatsbyImageData}
        alt={image.name}
        style={{ position: "absolute", top: -10, right: 35 }}
      />
    </div>
  );
};
