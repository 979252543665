import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { graphql, useStaticQuery } from "gatsby";
import Shop from "../components/shop";
import PageLayout from "../components/pageLayout";

const ShopPage = () => {
  const { allContent } = useStaticQuery(query);

  let projects = allContent.edges.map((edge, index) => (
    <Shop
      key={index}
      urlPrefix={"shop"}
      title={edge.node.title}
      marked={edge.node.marked}
      tags={edge.node.tags}
      price={edge.node.price}
      picture={edge.node.titlePicture?.childImageSharp.gatsbyImageData}
    />
  ));

  return (
    <PageLayout title={"Shop"} onShop={true}>
      <div className={`row ml-2 ml-sm-4 mr-2 mr-sm-4`}>{projects}</div>
      <br />
    </PageLayout>
  );
};

export default ShopPage;

const query = graphql`
  {
    allContent(
      sort: { fields: added, order: DESC },
      filter: {
        hidden: {ne: true},
        parent: {internal: {description: {regex: "/shop/"}}}
      }) {
      edges {
        node {
          title
          tags
          marked
          price
          titlePicture {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`;
